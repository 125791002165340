import React, { useState } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"

// Images
import main__top__image from "../images/servers.png"
import GradientImage from "../images/gradient-homepage.png"
import BlueLabel from "../images/logo-blue-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import BlueLabelEU from "../images/europe/logo.svg"
import GoldLabelEU from "../images/europe/download-logo-gold.svg"
import SilverLabelEU from "../images/europe/download-logo-silver.svg"

const IndexPage = ({ data }) => {
  // home page states
  const [tabs, setTabs] = useState(() => {
    return new Array(data.datoCmsHomepage.homepageBlock4.length ?? []).fill(
      false
    )
  })

  function switchTab(index) {
    setTabs(previousState => {
      return previousState.map((tab, i) => i === index && !previousState[i])
    })
  }

  const DetailsButton = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            bottom: 20,
          }}
          onClick={() => navigate("/en/labeloverview")}
        >
          <p
            style={{
              fontWeight: 700,
              fontSize: 12,
              lineHeight: "15px",
              color: "#0B67F0",
            }}
          >
            DETAILS
          </p>
          <svg
            width="36"
            height="19"
            viewBox="0 0 36 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              marginLeft: 10,
            }}
          >
            <path
              d="M0 9.5H35M35 9.5L26.3084 1M35 9.5L26.3084 18"
              stroke="#0B67F0"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </>
    )
  }

  return (
    <div>
      <PageTitle title={`Cyber Trust ${process.env.GATSBY_VARIANT}`} />
      <Layout>
        <div className="homepage-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>

        <div className="page__wrap" style={{ maxWidth: 1440 }}>
          <div className="main__top">
            <div className="row" style={{ margin: "unset" }}>
              <div className="main__top--box">
                <h1>{data.datoCmsHomepage.homepageBlock1[0].title}</h1>
                <div
                  className="main__top--box--description"
                  dangerouslySetInnerHTML={{
                    __html: data.datoCmsHomepage.homepageBlock1[0].description,
                  }}
                />
              </div>
              <div className="main__top--box">
                <div className="image">
                  <img
                    src={main__top__image}
                    className="mw-100"
                    alt="Cyber Trust Austria: Österreichisches Label für Cybersicherheit"
                    title="Cyber Trust Austria: Österreichisches Label für Cybersicherheit"
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className="main__trust">
            <div className="main__trust--block">
              <h2>{data.datoCmsHomepage.homepageBlock2[0].title}</h2>
              <div
                className="main__trust--block--description"
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsHomepage.homepageBlock2[0].description,
                }}
              />
            </div>
          </div>

          <div className="main__labels_new">
            <h2>{data.datoCmsHomepage.homepageBlock3New[0].title}</h2>
            <div className="main__labels_new--container">
              <div className="main__labels_new--container--images">
                <div className="image">
                  <img
                    className="mw-100"
                    src={
                      process.env.GATSBY_VARIANT === "Austria"
                        ? GoldLabel
                        : GoldLabelEU
                    }
                    style={{ height: "59px" }}
                    alt="Cyber Trust Austria Gold Label"
                    title="Cyber Trust Austria Gold Label"
                  ></img>
                </div>
                <div className="image">
                  <img
                    className="mw-100"
                    src={
                      process.env.GATSBY_VARIANT === "Austria"
                        ? SilverLabel
                        : SilverLabelEU
                    }
                    style={{ height: "59px" }}
                    alt="Cyber Trust Austria Silber Label"
                    title="Cyber Trust Austria Silber Label"
                  ></img>
                </div>
                <div className="image">
                  <img
                    className="mw-100"
                    src={
                      process.env.GATSBY_VARIANT === "Austria"
                        ? BlueLabel
                        : BlueLabelEU
                    }
                    style={{ height: "59px" }}
                    alt="Cyber Trust Austria Standard Label"
                    title="Cyber Trust Austria Standard Label"
                  ></img>
                </div>
              </div>
              <div>
                <div
                  className="main__labels_new--card--bottom--text"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.datoCmsHomepage.homepageBlock3New[0].description,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="main__overview">
            <div className="main__overview--text--container">
              <p>
                Get an overview of your suppliers & service providers and ensure
                compliance with the NIS directive.
              </p>
            </div>

            <div className="main__overview--buttons--container">
              <a
                className={"page__btn page__btn--blue"}
                href="/en/supplier-risk-management"
              >
                Manage Supply Chain Risk Management
              </a>
              <a
                className={"page__btn page__btn--blue"}
                href="/en/labeloverview"
              >
                Overview of Labels
              </a>
            </div>
          </div>

          <div className="main__tabs">
            {data.datoCmsHomepage.homepageBlock4.map((item, index) => (
              <div className="main__tab--element" key={index}>
                <div
                  className="asked-question__accordion--name js-accordion__name"
                  onClick={() => switchTab(index)}
                >
                  <h3>{item.question}</h3>
                  {tabs[index] ? (
                    <svg
                      width="44"
                      height="43"
                      viewBox="0 0 44 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.15645 42.1525L42.1686 1.14035M2.21711 0.786799L43.2293 41.799"
                        stroke="#0B67F0"
                        strokeWidth="2"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="58"
                      height="58"
                      viewBox="0 0 58 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 28.5H58M30 0L30 58"
                        stroke="#0B67F0"
                        strokeWidth="2"
                      />
                    </svg>
                  )}
                </div>
                <div
                  className={`main__tab--item${tabs[index] ? " active" : ""} ${item.divisions == 1 ? " one-division" : "two-divisions"
                    }`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.answerLeft,
                    }}
                  />
                  {item.divisions == 2 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.answerRight,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="main__contact">
            <div className="main__contact--text--container">
              <h2>
                <b>Do you have any questions? Please contact us.</b>
              </h2>
            </div>

            <div
              className="main__contact--buttons--container"
              style={{ marginTop: 20 }}
            >
              <a
                className={"page__btn page__btn--blue"}
                href="https://calendly.com/cyber-trust/free-advice"
                target="_blank"
              >
                Schedule a Free Call
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query ($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      homepageBlock1 {
        description
        title
      }
      homepageBlock2 {
        productTwoDescription
        productOneTitle
        productOneDescription
        productTwoTitle
        title
        description
      }
      homepageBlock3 {
        description
        title
      }
      homepageBlock3New {
        description
        title
      }
      homepageBlock4 {
        question
        answerLeft
        answerRight
        divisions
      }
    }
  }
`
